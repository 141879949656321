body {
  overflow: hidden;
}

iframe {
 width: 100vw;
 height: 100vh;
 border: none;
 margin: 0;
}

#__framer-badge-container .__framer-badge, .framer-TLVk2 {
  display:none !important;
  background: forestgreen;
}